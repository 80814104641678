<template>
  <div>
    <div style="font-size: 36px">{{ $t('tools.a8') }}</div>
    <div class="phone-parameters flex">
      <el-upload
          class="upload-demo"
          action="https://jsonplaceholder.typicode.com/posts/"
          ref="uploadRef"
          :limit="1"
          :show-file-list="false"
          :http-request="upload"
          :before-upload="fileUpload">
        <el-button type="primary">{{ $t("tools.a26") }}</el-button>
      </el-upload>
      <el-button  type="primary" @click="cleanParameters">{{ $t("tools.clean_para") }}</el-button>
    </div>
    <ui class="desc-info">
      <li>IMEI：{{ imei.imei }}</li>
      <li>{{ $t('tools.a9') }}：{{ imei.version }}</li>
      <li>{{ $t('tools.a10') }}: {{ imei.profileName }}</li>
      <li>{{ $t('tools.a11') }}: {{ imei.profileVersion }}</li>
      <!-- <li>call log：{{ imei.imei }}</li> -->
    </ui>
  </div>
</template>

<script>

export default {
  data() {
    return {
      pid: "",
      id: "",

      imei: {
        imei: '',
        version: '',
        profileName: '',
        profileVersion: '',
        callLog: '',
      }
    };
  },
  created() {
    // sessionStorage.removeItem('imeiInfo')
    let imeiInfo = sessionStorage.getItem('imeiInfo');
    if (imeiInfo) {
      this.imei = JSON.parse(imeiInfo)
    }
    let data = sessionStorage.getItem("file");
    if (data) {
      this.pid = JSON.parse(data).id;
    }
    // this.edit = sessionStorage.getItem('edit');
    this.getList();
  },

  methods: {
    getList() {
      let params = {
        uid: sessionStorage.getItem("id"),
        pid: this.pid,
      };
      this.postRequest("index/get_menus", params, (res) => {
        if (res.data) {
          this.id = res.data.id;
          delete res.data.id;
          delete res.data.uid;
          let arr = [];
          for (let i in res.data) {
            arr.push({
              key: i,
              value: res.data[i] == 1 ? true : false,
            });
          }
          this.tableData = arr;
        }
      });
    },
    upload(file) {
      let formdata = new FormData();
      formdata.append('file', file.file)
      formdata.append('uid', sessionStorage.getItem('id'))
      this.postFile('index/read_phone_version', formdata, (res) => {
        this.imei.version = res.data.phone_version;
        this.imei.imei = res.data.imei;
        this.imei.profileName = res.data.profile_name;
        this.imei.profileVersion = res.data.profile_version;
        sessionStorage.setItem('imeiInfo', JSON.stringify(this.imei));
        //uploadRef.handleRemove(file.file)
        this.$refs.uploadRef.clearFiles()
        file = null
      })
      // this.postRequest('index/read_phone_version', {
      //   uid: sessionStorage.getItem('id'),
      //   phone_version: this.imei.version,
      //   imei: this.imei.imei,
      //   profile_name: this.imei.profileName,
      //   profile_version: this.imei.profileVersion
      // }, (res) => {},true)
    },
    
    cleanParameters(){
      this.imei.version = '';
      this.imei.imei = '';
      this.imei.profileName = '';
      this.imei.profileVersion = '';
      sessionStorage.setItem('imeiInfo', JSON.stringify(this.imei));
    },

    fileUpload(file) {
      // var reader = new FileReader()
      // reader.readAsText(file,'utf-8')
      // reader.onload = (e)=> {
      //   var data = e.target.result;
      //   let xmlDoc = new DOMParser().parseFromString(data, "text/xml");
      //   let cont = xmlDoc.documentElement.textContent.split('\n').filter(v => v? true : false).map(v => v.trim())
      //   this.imei.imei = cont[1];
      //   this.imei.version = cont[0];
      //   this.imei.profileName = cont[2];
      //   this.imei.profileVersion = cont[3];
      //   sessionStorage.setItem('imeiInfo', JSON.stringify(this.imei))
      // }
    },
    back() {
      this.$router.go(-1);
    },
    next() {
      let params = {
        pid: this.pid,
        uid: sessionStorage.getItem("id"),
      };
      if (this.id) params.id = this.id;
      this.tableData.forEach((v) => {
        params[v.key] = v.value == true ? 1 : 0;
      });
      this.postRequest("index/set_menus", params, (res) => {
        if (res.data) {
          this.$router.push("/Shortcutkeys?id=" + res.data);
        }
      });
    },
  },
};
</script>

<style>
.phone-parameters .el-button {
  /*width: 180px !important;*/
}
</style>
<style scoped>
.desc-info {
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #999999;
  line-height: 30px;
}

.search:focus-visible {
  outline: none;
}

.search-btn {
  width: 6%;
  height: 46px;
  line-height: 46px;
  text-align: center;
  font-size: 22px;
  cursor: pointer;
  color: #C3D0FB;
  background: #5D80F5;
}

.flex {
  display: flex;
  justify-content: space-between;
}

.tool-btn {
}

.phone-parameters {
  margin: 30px 0;
}
</style>
